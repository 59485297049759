import React, { Component } from "react";
import chevron_up from "../img/chevron_up.svg";

class Footer2 extends Component {
  render() {
    return (
      <div className="bodyFooter">
        <div className="text-center texte_footer">
          Créé par Guillaume Queste, 2021
        </div>
      </div>
    );
  }
}

export default Footer2;
